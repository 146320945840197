<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-3-4banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">商情诊断管理分析</div>
            <div class="head-explainBox-content1">中国某知名调味品生产公司</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">客户公司在业务快速发展的同时，非常重视数据方面的投入。数据仓库已实施上线多年，虽然为业务扩张提
                供了有力的数据支持，但仍面临诸多挑战。通过商情诊断管理分析项目，并借助解决方案中针对产品、客户、区域的销售分析体系模板，业务部门结合
                企业自身情况建立了分析指标。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">实施方案</span></div>
            <div class="head-list-item"><span class="title">方实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">市场/销售数据难以支持精准决策</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据时效性无法满足业务的需求</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据质量需要进行改进</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据服务成本较高</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 31rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4销售执行.svg"/>
                        <div>
                            <div class="title1">销售执行分析模块  </div>
                            <div class="title2">轻松、方便地创建或修改主数据。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4销售结构.svg"/>
                        <div>
                            <div class="title1">销售结构分析模块</div>
                            <div class="title2">平台可针对各个产品、各个客户、各个区域销售情况进行深入分析</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4营销活动.svg"/>
                        <div>
                            <div class="title1">营销活动ROI分析模块</div>
                            <div class="title2">平台提供了收入、成本、收益率分析功能，帮助客户及时掌握各营销活动盈利情况</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">平台帮助管理层与销售部门全面了解业务</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">营销与渠道分析应用达到毫秒级的数据分析响应能力</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">在指标框架指导下，对指标进行了标准化和细化描述，统一理解和计算逻辑</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">ETL整体执行效率较线下的数据仓库提升 <div class="title-6"> 6 </div>倍</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">相比使用本地IDC，使用Amazon降低总体拥有成本（TCO）50%</div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #ED5847;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 26.7rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 14.6rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #ED5847;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
